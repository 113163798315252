import aaron_web from '/static/img/works/aaron.jpg'
import brendan_web from '/static/img/works/brendan.jpg'
import brunswick_yard_web from '/static/img/works/brunswick-yard.jpg'
import build_web from '/static/img/works/build.jpg'
import cartier_web from '/static/img/works/cartier.jpg'
import collins_collins_web from '/static/img/works/collins-collins.jpg'
import compass_web from '/static/img/works/compass.jpg'
import comwire_web from '/static/img/works/comwire.jpg'
import etc_web from '/static/img/works/etc.jpg'
import everything_about_web from '/static/img/works/everything-about.jpg'
import golden_fox_web from '/static/img/works/golden-fox.jpg'
import hsh_web from '/static/img/works/hsh.jpg'
import jackmckinley_web from '/static/img/works/jack-mckinley.jpg'
import koji_web from '/static/img/works/koji.jpg'
import martay_web from '/static/img/works/martay.jpg'
import mernda_web from '/static/img/works/mernda.jpg'
import michaelsincere_web from '/static/img/works/michael-sincere.jpg'
import morris_moor_web from '/static/img/works/morris-moor.jpg'
import mycelium_web from '/static/img/works/mycelium.jpg'
import piertwo_web from '/static/img/works/pier-two.jpg'
import playground_web from '/static/img/works/playground.jpg'
import ridge_projects_web from '/static/img/works/ridge-projects.jpg'
import stake_pier_two_web from '/static/img/works/stake-pier-two.jpg'
import stake_web from '/static/img/works/stake.jpg'
import state_library_exchange_web from '/static/img/works/state-library-exchange.jpg'
import stitchhire_web from '/static/img/works/stitch-hire.jpg'
import sunrise_labels_web from '/static/img/works/sunrise-labels.jpg'
import swaps_web from '/static/img/works/swaps.jpg'
import teacher_presence_web from '/static/img/works/teacher-presence.jpg'
import terence_tan_web from '/static/img/works/terence-tan.jpg'
import term_finance_web from '/static/img/works/term-finance.jpg'
import tracer_web from '/static/img/works/tracer.jpg'
import unconfined_web from '/static/img/works/unconfined.jpg'

export const worksData = [
  {
    year: '2024',
    title: 'Sunrise Labels',
    link: 'https://sunriselabels.com.au',
    tags: ['Next.js', 'TypeScript', 'TailwindCSS', 'Headless CMS'],
    featured: false,
    image: sunrise_labels_web,
  },
  {
    year: '2024',
    title: 'Morris Moor',
    link: 'https://morrismoor.com',
    tags: ['Next.js', 'TypeScript', 'TailwindCSS', 'Headless CMS'],
    featured: true,
    image: morris_moor_web,
  },
  {
    year: '2024',
    title: 'Teacher Presence',
    link: 'https://teacherpresence.org',
    tags: ['Next.js', 'TypeScript', 'TailwindCSS', 'Headless CMS'],
    featured: true,
    image: teacher_presence_web,
  },
  {
    year: '2023',
    title: 'Ridge Projects',
    link: 'https://ridgeprojects.com.au',
    tags: ['Next.js', 'TypeScript', 'TailwindCSS', 'Headless CMS'],
    featured: false,
    image: ridge_projects_web,
  },
  {
    year: '2023',
    title: 'State Library Exchange',
    link: 'https://statelibraryexchange.com.au',
    tags: ['Next.js', 'TypeScript', 'TailwindCSS', 'Headless CMS'],
    featured: true,
    image: state_library_exchange_web,
  },
  {
    year: '2023',
    title: 'Terence Tan',
    link: 'https://ttneuro.com',
    tags: ['Next.js', 'TypeScript', 'TailwindCSS', 'Headless CMS'],
    featured: false,
    image: terence_tan_web,
  },
  {
    year: '2023',
    title: 'CollinsCollins',
    link: 'https://collinscollins.melbourne',
    tags: ['Next.js', 'TypeScript', 'TailwindCSS', 'Headless CMS'],
    featured: true,
    image: collins_collins_web,
  },
  {
    year: '2023',
    title: 'Playground Studio',
    link: 'https://playgroundstudio.com.au',
    tags: ['Next.js', 'TypeScript', 'TailwindCSS', 'Headless CMS'],
    featured: true,
    image: playground_web,
  },
  {
    year: '2023',
    title: 'etc.',
    link: 'https://etc.melbourne',
    // link: 'https://etc.com.au',
    tags: ['Next.js', 'TypeScript', 'TailwindCSS', 'Headless CMS'],
    featured: true,
    image: etc_web,
  },
  {
    year: '2023',
    title: 'Brunswick Yard',
    link: 'https://brunswickyard.com.au',
    tags: ['Next.js', 'TypeScript', 'TailwindCSS', 'Headless CMS'],
    featured: true,
    image: brunswick_yard_web,
  },
  {
    year: '2023',
    title: 'Golden Fox',
    link: 'https://goldenfox.com.au',
    tags: ['Next.js', 'TypeScript', 'TailwindCSS', 'Headless CMS'],
    featured: false,
    image: golden_fox_web,
  },
  {
    year: '2023',
    title: 'Term Finance',
    link: 'https://app.term.finance',
    tags: ['React', 'TypeScript', 'Chakra UI', 'Storybook'],
    featured: false,
    image: term_finance_web,
  },
  {
    year: '2023',
    title: 'Pier Two Staking',
    link: 'https://stake.piertwo.com',
    tags: ['React', 'TypeScript', 'TailwindCSS', 'Headless CMS', 'Web3'],
    featured: false,
    image: stake_pier_two_web,
  },
  {
    year: '2023',
    title: 'Comwire',
    link: 'https://comwireit.com.au',
    tags: ['Next.js', 'TypeScript', 'TailwindCSS', 'Headless CMS'],
    featured: true,
    image: comwire_web,
  },
  {
    year: '2023',
    title: 'Stitch Hire',
    link: 'https://stitch-landing-site-rk8xrjq4z-stitch-hire-v1.vercel.app',
    tags: ['Next.js', 'TypeScript', 'TailwindCSS', 'Headless CMS'],
    featured: false,
    image: stitchhire_web,
  },
  {
    year: '2023',
    title: 'Pier Two',
    link: 'https://piertwo.com',
    tags: ['Next.js', 'TypeScript', 'TailwindCSS', 'Headless CMS'],
    featured: false,
    image: piertwo_web,
  },
  {
    year: '2023',
    title: 'Unconfined',
    link: 'https://unconfined-site.vercel.app',
    tags: ['Next.js', 'TypeScript', 'TailwindCSS', 'Shopify'],
    featured: false,
    image: unconfined_web,
  },
  {
    year: '2022',
    title: 'Jack McKinley',
    link: 'https://jackmckinley.com',
    tags: ['Next.js', 'TypeScript', 'TailwindCSS'],
    featured: true,
    image: jackmckinley_web,
  },
  {
    year: '2022',
    title: 'Michael Sincere',
    link: 'https://michaelsincere.com',
    tags: ['Next.js', 'TypeScript', 'TailwindCSS', 'Headless CMS'],
    featured: false,
    image: michaelsincere_web,
  },
  // {
  //   year: '2022',
  //   title: 'Instant Checkout',
  //   link: 'https://instant-landing-site.vercel.app',
  //   tags: ['Next.js', 'TypeScript', 'TailwindCSS', 'Headless CMS'],
  //   featured: false,
  //   image: instant_web,
  // },
  {
    year: '2022',
    title: 'Mernda Town Centre',
    link: 'https://merndatowncentre.au',
    tags: ['Next.js', 'TypeScript', 'TailwindCSS', 'Headless CMS'],
    featured: true,
    image: mernda_web,
  },
  {
    year: '2022',
    title: 'MYC Staking',
    link: 'https://stake.mycelium.xyz',
    tags: ['Next.js', 'TypeScript', 'TailwindCSS', 'Web3'],
    featured: false,
    image: stake_web,
  },
  {
    year: '2022',
    title: 'Perpetual Swaps',
    link: 'https://perpetual-swaps-mwjzi2ou1-mycelium.vercel.app',
    tags: ['React', 'TypeScript', 'Styled Components', 'Web3'],
    featured: false,
    image: swaps_web,
  },
  // {
  //   year: '2022',
  //   title: 'Perpetual Pools',
  //   link: 'https://pools.mycelium.xyz',
  //   tags: ['Next.js', 'TypeScript', 'Styled Components', 'Web3'],
  //   featured: false,
  //   image: pools_web,
  // },
  // {
  //   year: '2022',
  //   title: 'Mycelium Analytics',
  //   link: 'https://analytics.mycelium.xyz',
  //   tags: ['Razzle.js', 'TypeScript', 'Styled Components', 'Web3'],
  //   featured: false,
  //   image: analytics_web,
  // },
  {
    year: '2022',
    title: 'Mycelium',
    link: 'https://mcv-website-p42g9qttl-mycelium.vercel.app',
    tags: ['React', 'TypeScript', 'TailwindCSS', 'Headless CMS'],
    featured: true,
    image: mycelium_web,
  },
  {
    year: '2022',
    title: 'Build_',
    link: 'https://app.buildcities.network',
    tags: ['Redwood.js', 'TypeScript', 'TailwindCSS', 'Bit.dev', 'Web3'],
    featured: false,
    image: build_web,
  },
  {
    year: '2022',
    title: 'Yerba Martay',
    link: 'https://yerbamartay.com.au',
    tags: ['Next.js', 'TypeScript', 'TailwindCSS', 'Shopify'],
    featured: true,
    image: martay_web,
  },
  {
    year: '2021',
    title: 'Tracer Finance',
    link: 'https://tracer-pools-website-v1-rliiqo4w6-mycelium.vercel.app',
    tags: ['Gatsby.js', 'TailwindCSS', 'Headless CMS'],
    featured: true,
    image: tracer_web,
  },
  {
    year: '2021',
    title: 'Koji Capital',
    link: 'https://koji.capital',
    tags: ['Gatsby.js', 'CSS3', 'Headless CMS'],
    featured: true,
    image: koji_web,
  },
  {
    year: '2021',
    title: 'Compass Legal',
    link: 'https://compasslegalsolutions.com.au',
    tags: ['HTML5', 'CSS3', 'jQuery'],
    featured: false,
    image: compass_web,
  },
  {
    year: '2021',
    title: 'HSH Lawyers',
    link: 'https://hsh-lawyers.vercel.app',
    tags: ['HTML5', 'CSS3', 'jQuery'],
    featured: false,
    image: hsh_web,
  },
  {
    year: '2021',
    title: 'Cartier Group Enterprise',
    link: 'https://cartiergroupenterprise.com',
    tags: ['HTML5', 'CSS3', 'jQuery'],
    featured: false,
    image: cartier_web,
  },
  {
    year: '2019',
    title: 'Everything About Finance',
    link: 'https://everything-about-finance.com',
    tags: ['PHP', 'CSS3', 'jQuery', 'AWS'],
    featured: false,
    image: everything_about_web,
  },
  {
    year: '2019',
    title: 'Brendan Anning',
    link: 'https://brendananning.wedding',
    tags: ['HTML5', 'CSS3', 'jQuery'],
    featured: false,
    image: brendan_web,
  },
  {
    year: '2018',
    title: 'Aaron Maybus',
    link: 'https://aaronmaybus.com',
    tags: ['HTML5', 'CSS3', 'jQuery'],
    featured: false,
    image: aaron_web,
  },
]
